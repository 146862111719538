import { CustomAutomatedEmailSenderType } from './custom-automated-email-sender-type';

export enum EventEntryType {
  BY_INVITE_ONLY = 'by_invite_only',
  PUBLIC = 'public',
}

export enum Webinar3rdPartyIntegration {
  KAHOOT = 'kahoot',
  GOOGLE_DOC = 'google_doc',
  KUDO = 'kudo',
  SLIDO = 'slido',
}

export const featureFlagKeys = [
  // Release Control feature flags (Apply to all events)
  'isAiEngineTDCEnabled',
  'isAnalyticsDataSourceFootprintEnabled',
  'isAnnouncementDisabled',
  'isAnonymousAttendeeEnabled',
  'isApprovalMechanismConfigEnabled',
  'isAttendeeDuplicatedOrEmptyEmailEnabled',
  'isAttendeeFirstNameAndLastNameEnabled',
  'isCheckInAppLoginQRCodeV2Enabled',
  'isAttendeeSecondaryEmailsEnabled',
  'isAttendeeRsvpInvitationEnabled',
  'isAttendeeRsvpInvitationResendEnabled',
  'isAutoSignUpEnabled',
  'isBoothCategorySearchEnabled',
  'isBoothFormSubmissionEnabled',
  'isBulkUpdateAttendeeEnabled',
  'isChatEnabled',
  'isCreditSystemOutreachManualEmailEnabled',
  'isCustomFieldImportEnabled',
  'isEnhancedDefaultLocaleEnabled',
  'isLeadCaptureEnabled',
  'isMagicLinkRetrieveFormEnabled',
  'isMultiRegFormRegisteredCountEnabled',
  'isOnboardingVideoSettingEnabled',
  'isOutreachManualEmailEnabled',
  'isAccurateOutreachEmailStatisticsEnabled',
  'isPhysicalSessionPermissionControlEnabled',
  'isProductCategorySearchEnabled',
  'isProductStateConfigEnabled',
  'isPromoCodeEnabled',
  'isShowingEventSupportedLocalesOnlyEnabled',
  'isSmsEnabled',
  'isSpeakerOnboardingInstantlyEnabled',
  'isWebinarRealtimeQnAEnabled',
  'isWhatsappOutgoingMessageEnabled',
  'isWltRecordingBackupEnabled',
  'isExtendedAttendeeCheckInMethodEnabled',
  'isSimplifiedRegistrationFlowEnabled',
  'isRegistrationFlowV3Enabled',
  'isPhysicalCheckInCustomNameBadgeEnabled',
  'isAttendeeHybridStatusEnabled',
  'isPushNotificationEnabled',
  'isCustomFieldMergeTagsEnabled',
  'isCustomFieldFileUploadEnabled',
  'isSidebarCollapseButtonEnabled',
  'isRegFormConditionalLogicEnabled',
  'isDuplicateEmailRegistrationEnabled',
  'isDefaultRegFormEnabled',
  'isMeetupEnabled',
  'isUnifiedAccessEnabled',
  'isEventOfflinePaymentEnabled',
  'isEventEntrySettingEnabled',
  'isWhatsappOutgoingMessageWithPhysicalSessionEnabled',
  'isTicketClassAddOnEnabled',
  'isMultiRegFormsWithDifferentTicketingEnabled',
  'isMeetupAlwaysOnEnabled',
  'isAttendeeExportWithAttendanceEnabled',
  'isTicketQuantityPerOrderEditableEnabled',
  'isScheduleSessionEnabled',
  'isPromoCodeAppliedToTicketEnabled',
  'isUnlayerOrderSummaryEmailEnabled',
  'isRsvpDuplicatedEmailEnabled',
  'isOutreachEmailCopyEnabled',
  'isRsvpStatusRevisionEnabled',
  'isWidgetRegistrationFlowV3Enabled',
  'isOutreachEmailHistoryEnabled',
  'isAttendeeShortenQRCodeTokenEnabled',
  'isAttendeeActivityEnabled',
  'isOverRegistrationEnabled',
  'isCheckInAppFirstNameAndLastNameV2Enabled',
  'isRegFormPurchaserNameEnabled',
  'isCheckInAppRequiredFieldsSettingEnabled',
  'isCheckInAppAttendeeTagEnabled',
  'isCheckInAppBadgeEditorV2Enabled',
  'isPortalDashboardV2Enabled',
  'isAttendeeRsvpInvitationLinkV2Enabled',

  // Pricing Plan feature flags (Apply to Enterprise events)
  'isAttendeeExplorerEnabled',
  'isAttendeeExportingEnabled',
  'isAttendeeGenerationEnabled',
  'isAttendeeGenerationPaidEnabled',
  'isAttendeeProfileCustomizationEnabled',
  'isBoothEnabled',
  'isBoothRepresentativeListEnabled',
  'isCheckInAppEnabled',
  'isConcurrentLoginDisabled',
  'isContentI18nEnabled',
  'isCsvImportEncodingDetectionDisabled',
  'isEmailSendingEnabled',
  'isEventPageCustomizationEnabled',
  'isGamificationBadgeCollectionEnabled',
  'isGeneralSearchEnabled',
  'isInEventRealtimeDashboardEnabled',
  'isInstantPollEnabled',
  'isInstantQnAEnabled',
  'isLeadCaptureBusinessCardEnabled',
  'isLobbyEnabled',
  'isMagicLinkEnabled',
  'isMeetupAlwaysOnEnabled',
  'isMultipleTrackSessionEnabled',
  'isOverRegistrationEnabled',
  'isOverrideBoothContentConfigEnabled',
  'isProductCatalogEnabled',
  'isProductVideoWatermarkEnabled',
  'isRealTimeInEventAnalyticsEnabled',
  'isRegisteredAttendeesOnPeopleListEnabled',
  'isSpeakerPageEnabled',
  'isSpeedNetworkingEnabled',
  'isSponsorEnabled',
  'isVenueCustomizationEnabled',
  'isWebhookEnabled',
  'isWebinarAuditoriumEnabled',
  'isWebinarOnDemandSessionEnabled',
  'isWltHighQualityAudioEnabled',
  'isWltReplayEnabled',
  'isZendeskOnPortalSideEnabled',
  'isWhiteLabelEnabled',
  'isSkipEmailForFreeOrderEnabled',
  'isSkipApprovalMechanismForRSVPEnabled',
  'isLeadFinderAIEmailEnabled',
  'isLeadFinderAILookalikePersonaEnabled',
  'isPersonalizationMergeTagEnabled',
  'isWltEnabled',

  // Organization Feature Flags
  'isAllAttendeesLeadCaptureLicensedEnabled',
  'isHandheldScannerSupportingEnabled',
  'isSalesforceIntegrationEnabled',
  'isStripeAlipayEnabled',
  'isInviteAttendeeEnabled',
  'isWebinarToolZoomIntegrationEnabled',
] as const;

export type FeatureFlagKey = (typeof featureFlagKeys)[number];

export type FeatureFlagMapping = Record<FeatureFlagKey, boolean>;

export type FeatureLimit = {
  extraEntryTypes: EventEntryType[];
  maxEventDurationInHour: number | null; // null means unlimited
  /** @deprecated */
  maxNumberOfAttendees: number;
  maxNumberOfEventManagers: number;
  /** @deprecated */
  maxNumberOfEventRegistrants: number | null; // null means unlimited
  maxNumberOfNetworkingLoungeSeatsPerTable: number;
  maxNumberOfNetworkingLoungeTables: number;
  maxVideoLibraryFileSizeLimitInMb: number;
  webinar3rdPartyIntegrations: Webinar3rdPartyIntegration[];
  wltMaxStreamingQuality: '480p' | '720p' | '1080p';
  wltRecordingQuality: '480p' | '720p' | '1080p';
  maxNumberOfBulkUpdateAttendees: number;
  liveStreamingDisabledTicketIds: string[] | null;
  checkInAppDataSyncInterval: number | null; // default 20 * 1000ms

  // customized for HKTDC events
  attendeeTagsFieldId: string | null;
  aiEngineTDCRecommendationURL: string | null;

  // customized for AGG events and Cash Project
  customAutomatedEmailSender: CustomAutomatedEmailSenderType;

  // OrganizationFeatureConfig
  maxNumberOfOrgBooths: number | null; /// null by default, means unlimited
  displayTenantDbHostRegion: string | null;
  displayTenantDbHostCluster: string | null;
  /** @deprecated use attendee credit system instead */
  maxNumberOfOrgEventRegistrants: number | null; // null by default and means unlimited
  maxNumberOfOrganizationManagers: number; // 0 by default
};

export type FeatureLimitKey = keyof FeatureLimit;

export type FeatureConfig = {
  featureLimit: FeatureLimit;
  featureFlagMapping: FeatureFlagMapping;
};

export type EventFeatureConfig = FeatureFlagMapping & FeatureLimit;

export type PricingPlanEventFeatureConfig = Partial<
  Pick<
    FeatureFlagMapping,
    | 'isAttendeeExplorerEnabled' // Sunny, not pricingPlan, demo only, replace by AG https://github.com/eventxtra/esaas-web/pull/1411
    | 'isAttendeeExportingEnabled'
    | 'isAttendeeGenerationEnabled'
    | 'isAttendeeGenerationPaidEnabled'
    | 'isAttendeeProfileCustomizationEnabled' // not pricing plan
    | 'isBoothEnabled'
    | 'isBoothRepresentativeListEnabled'
    | 'isCheckInAppEnabled'
    | 'isConcurrentLoginDisabled' // Angus, not pricing plan
    | 'isContentI18nEnabled'
    | 'isCsvImportEncodingDetectionDisabled' // @todo For SCMP org usage only, should be removed when we have proper guideline for export from common tools, e.g. excel, google sheet, etc.
    | 'isEmailSendingEnabled'
    | 'isEventPageCustomizationEnabled'
    | 'isGamificationBadgeCollectionEnabled'
    | 'isGeneralSearchEnabled'
    | 'isInEventRealtimeDashboardEnabled'
    | 'isInstantPollEnabled' // ask carmen, not pricing plan
    | 'isInstantQnAEnabled'
    | 'isLeadCaptureBusinessCardEnabled'
    | 'isLobbyEnabled'
    | 'isMagicLinkEnabled'
    | 'isMeetupAlwaysOnEnabled'
    | 'isMultipleTrackSessionEnabled'
    | 'isOverrideBoothContentConfigEnabled' // ask ree
    | 'isProductCatalogEnabled'
    | 'isProductVideoWatermarkEnabled'
    | 'isRealTimeInEventAnalyticsEnabled'
    | 'isRegisteredAttendeesOnPeopleListEnabled'
    | 'isSpeakerPageEnabled'
    | 'isSpeedNetworkingEnabled'
    | 'isSponsorEnabled'
    | 'isVenueCustomizationEnabled'
    | 'isWebhookEnabled'
    | 'isWebinarAuditoriumEnabled'
    | 'isWebinarOnDemandSessionEnabled'
    | 'isWltHighQualityAudioEnabled' // Sunny, base on agora charge on mic config
    | 'isWltReplayEnabled'
    | 'isZendeskOnPortalSideEnabled'
    | 'isWhiteLabelEnabled'
    | 'isSkipEmailForFreeOrderEnabled'
    | 'isSkipApprovalMechanismForRSVPEnabled'
    | 'isLeadFinderAIEmailEnabled'
    | 'isLeadFinderAILookalikePersonaEnabled'
    | 'isPersonalizationMergeTagEnabled'
    | 'isWltEnabled'
  > &
    Pick<
      FeatureLimit,
      | 'extraEntryTypes'
      | 'maxEventDurationInHour'
      | 'maxNumberOfAttendees'
      | 'maxNumberOfEventManagers'
      | 'maxNumberOfEventRegistrants'
      | 'maxNumberOfNetworkingLoungeSeatsPerTable'
      | 'maxNumberOfNetworkingLoungeTables'
      | 'maxVideoLibraryFileSizeLimitInMb'
      | 'webinar3rdPartyIntegrations'
      | 'wltMaxStreamingQuality'
      | 'wltRecordingQuality'
      | 'liveStreamingDisabledTicketIds'
    >
>;

export type OrganizationFeatureConfig = Partial<
  Pick<
    FeatureFlagMapping,
    | 'isAllAttendeesLeadCaptureLicensedEnabled'
    | 'isHandheldScannerSupportingEnabled'
    | 'isSalesforceIntegrationEnabled'
    | 'isStripeAlipayEnabled'
    | 'isInviteAttendeeEnabled'
    | 'isWebinarToolZoomIntegrationEnabled'
  > &
    Pick<
      FeatureLimit,
      | 'maxNumberOfOrgBooths'
      | 'displayTenantDbHostRegion'
      | 'displayTenantDbHostCluster'
      | 'maxNumberOfOrgEventRegistrants'
      | 'maxNumberOfOrganizationManagers'
    >
>;
